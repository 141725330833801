




























































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Gameplay from '@/engine/models/gameplay'
import Player from '@/engine/models/player'
import audioService from '@/services/audio-service'

declare global {
  interface Window {
    gameplay: Gameplay
  }
}

@Component
export default class Setup extends Vue {

  public gameplay: Gameplay = new Gameplay()

  private MAX_DIFFICULTY = 4.55 // 3.9

  private step: number = 0

  private numTeams: number = 4
  private numPlayers: number = 1
  private initialScore: number = 303
  private enabledDouble: boolean = false

  private players: Player[] = [
    {
      avatar: 1,
      id: 0,
      name: 'Spieler 1',
      shots: 0,
      teamId: 0,
    },
    {
      avatar: 2,
      id: 1,
      name: 'Spieler 2',
      shots: 0,
      teamId: 1,
    },
    {
      avatar: 3,
      id: 2,
      name: 'Spieler 3',
      shots: 0,
      teamId: 2,
    },
    {
      avatar: 4,
      id: 3,
      name: 'Spieler 4',
      shots: 0,
      teamId: 3,
    },
  ]

  private playerNameAlreadyInUse = false

  private numDarts: number = 3

  // private mode: 'fmj' | 'pro' | 'none' = 'fmj'
  private enabledBull: boolean = false
  private enabledBBE: boolean = false
  private enabledBullForLand: boolean = false
  private enabledTopToBottom: boolean = false
  private enabledFriendlyFire: boolean = false
  private enabledConquerAll: boolean = false
  private enabled11R: boolean = false
  private enabled111: boolean = false
  private enabledDestroy: boolean = false
  private enabledAllOrNothing: boolean = false
  private enabledAbsolutePro: boolean = false
  private enabledESPPlus: boolean = false

  get slickOptions() {
    return {
      centerMode: true,
      centerPadding: '60px',
      slidesToShow: 3,
    }
  }

  get allPlayers() {
    return this.players
  }

  get numAllPlayers() {
    return this.allPlayers.length
  }

  get teamsSelected(): boolean {
    return this.players.filter((p) => p.teamId === 0).length === 2
  }

  get levelOfDifficulty() {
    let level = 165

    if (this.enabledBull) {
      level -= 20
    }
    if (this.enabledBBE) {
      level -= 25
    }
    if (this.enabledESPPlus) {
      level -= 20
    }
    if (this.enabledDestroy) {
      level -= 20
    }
    if (this.enabledConquerAll) {
      level -= 30
    }
    if (this.enabled11R) {
      level -= 20
    }
    if (this.enabled111) {
      level -= 5
    }
    if (this.enabledAbsolutePro) {
      level += 95
    }
    if (this.enabledFriendlyFire) {
      level += 50
    }
    if (this.enabledTopToBottom) {
      level += 40
    }
    if (this.enabledBullForLand) {
      level += 65
    }
    if (this.enabledAllOrNothing) {
      level += 40
    }
    return level
  }

  // get levelOfDifficultyInt() {
  //   return Math.ceil(this.levelOfDifficulty)
  // }

  get difficultyColor() {
    if (this.levelOfDifficulty <= 100) {
      return 'success'
    }
    if (this.levelOfDifficulty <= 200) {
      return 'warning'
    }
    return 'error'
  }

  get mode() {
    if (
      !this.enabled111 &&
      this.enabled11R &&
      !this.enabledAbsolutePro &&
      !this.enabledAllOrNothing &&
      this.enabledBBE &&
      !this.enabledBull &&
      !this.enabledBullForLand &&
      !this.enabledConquerAll &&
      this.enabledDestroy &&
      !this.enabledESPPlus &&
      !this.enabledFriendlyFire &&
      !this.enabledTopToBottom
    ) {
      return 'fmj'
    } else if (
      !this.enabled111 &&
      !this.enabled11R &&
      !this.enabledAbsolutePro &&
      this.enabledAllOrNothing &&
      this.enabledBBE &&
      !this.enabledBull &&
      !this.enabledBullForLand &&
      !this.enabledConquerAll &&
      this.enabledDestroy &&
      !this.enabledESPPlus &&
      !this.enabledFriendlyFire &&
      !this.enabledTopToBottom
    ) {
      return 'sabotage'
    } else if (
      this.enabled111 &&
      !this.enabled11R &&
      !this.enabledAbsolutePro &&
      !this.enabledAllOrNothing &&
      this.enabledBBE &&
      !this.enabledBull &&
      !this.enabledBullForLand &&
      this.enabledConquerAll &&
      this.enabledDestroy &&
      !this.enabledESPPlus &&
      !this.enabledFriendlyFire &&
      !this.enabledTopToBottom
    ) {
      return 'thermit'
    } else if (
      this.enabled111 &&
      this.enabled11R &&
      !this.enabledAbsolutePro &&
      this.enabledAllOrNothing &&
      this.enabledBBE &&
      !this.enabledBull &&
      !this.enabledBullForLand &&
      !this.enabledConquerAll &&
      !this.enabledDestroy &&
      !this.enabledESPPlus &&
      !this.enabledFriendlyFire &&
      !this.enabledTopToBottom
    ) {
      return 'wicked'
    } else if (
      this.enabled111 &&
      this.enabled11R &&
      !this.enabledAbsolutePro &&
      this.enabledAllOrNothing &&
      this.enabledBBE &&
      !this.enabledBull &&
      this.enabledBullForLand &&
      !this.enabledConquerAll &&
      this.enabledDestroy &&
      !this.enabledESPPlus &&
      !this.enabledFriendlyFire &&
      this.enabledTopToBottom
    ) {
      return 'outland'
    } else if (
      this.enabled111 &&
      this.enabled11R &&
      !this.enabledAbsolutePro &&
      !this.enabledAllOrNothing &&
      this.enabledBBE &&
      this.enabledBull &&
      !this.enabledBullForLand &&
      this.enabledConquerAll &&
      !this.enabledDestroy &&
      !this.enabledESPPlus &&
      this.enabledFriendlyFire &&
      !this.enabledTopToBottom
    ) {
      return 'arrival'
    } else if (
      !this.enabled111 &&
      this.enabled11R &&
      !this.enabledAbsolutePro &&
      !this.enabledAllOrNothing &&
      this.enabledBBE &&
      !this.enabledBull &&
      this.enabledBullForLand &&
      !this.enabledConquerAll &&
      this.enabledDestroy &&
      !this.enabledESPPlus &&
      !this.enabledFriendlyFire &&
      !this.enabledTopToBottom
    ) {
      return 'lockout'
    } else if (
      !this.enabled111 &&
      !this.enabled11R &&
      !this.enabledAbsolutePro &&
      !this.enabledAllOrNothing &&
      !this.enabledBBE &&
      !this.enabledBull &&
      !this.enabledBullForLand &&
      !this.enabledConquerAll &&
      !this.enabledDestroy &&
      !this.enabledESPPlus &&
      !this.enabledFriendlyFire &&
      !this.enabledTopToBottom
    ) {
      return 'pro'
    }
    return 'none'
  }

  @Watch('enabledBullForLand')
  private enabledBullForLandChanged(val: boolean) {
    if (val) {
      this.numDarts = 5
    }
  }

  @Watch('enabledBull')
  private enabledBullChanged(val: boolean) {
    if (val === false) {
      this.enabledESPPlus = false
    }
  }

  private selectNumPlayers(n: number) {
    this.players = []
    for (let i = 0; i < n; i++) {
      this.players.push({
        id: i,
        name: '',
        avatar: i + 1,
        teamId: i,
        shots: 0,
      })
    }
    this.numTeams = n
    this.numPlayers = 1
    this.step++
    this.$nextTick(() => {
      this.focus(0)
    })
  }

  private next() {
    // Überspringe Team Setup, wenn die Anzahl der Spieler !== 4
    if (this.step === 2 * this.numAllPlayers && this.numAllPlayers !== 4) {
      this.step += 3
      return
    }

    // Für Bull For Land keine Pfeilauswahl anzeigen.
    if (this.step === 2 * this.numAllPlayers + 4 && this.enabledBullForLand) {
      this.start()
      return
    }

    this.step++

    if (this.step === 3) {
      this.focus(1)
    }
    if (this.numTeams > 2 && this.step === 5) {
      this.focus(2)
    }
    if (this.numTeams > 3 && this.step === 7) {
      this.focus(3)
    }
  }

  private nameInUse(n: number) {
    const name = this.players[n - 1].name.toLowerCase()
    const count = this.players
      .map((p) => p.name.toLowerCase())
      .filter((na) => na === name).length

    this.playerNameAlreadyInUse = count > 1
  }

  private nameSelected(n: number) {
    this.players[n - 1].name = this.players[n - 1].name || `Spieler ${n}`
    this.step++
  }

  private focus(n: number) {
    this.$nextTick(() => {
      const inputs = this.$refs.nameInput
      if (inputs && Array.isArray(inputs)) {
        const input: any = inputs[n]
        input.focus()
      }
    })
  }

  private selectTeams() {
    this.numTeams = 2
    this.numPlayers = 2
    for (const player of this.players) {
      player.teamId = -1
    }
    this.step++
  }

  private selectSinglePlay() {
    this.numPlayers = 1
    this.step += 2
  }

  private selectForTeam(player: Player) {
    if (player.teamId === 0) {
      player.teamId = -1
    } else if (player.teamId > 0) {
      player.teamId = 1
    } else {
      player.teamId = 0
    }
    if (this.teamsSelected) {
      for (const p of this.players) {
        if (p.teamId !== 0) {
          p.teamId = 1
        }
      }
    }
  }

  private confirmTeams() {
    this.step++
  }

  private selectInitialScore(score: number) {
    this.initialScore = score
    this.setFMJ()
    this.step++
  }

  private selectNumDarts(n: number) {
    this.numDarts = n
    this.start()
  }

  // FMJ = Full Metal Jacket
  private setFMJ() {
    audioService.playClickButton()
    this.enabled111 = false
    this.enabled11R = true
    this.enabledAbsolutePro = false
    this.enabledAllOrNothing = false
    this.enabledBBE = true
    this.enabledBull = false
    this.enabledBullForLand = false
    this.enabledConquerAll = false
    this.enabledDestroy = true
    this.enabledESPPlus = false
    this.enabledFriendlyFire = false
    this.enabledTopToBottom = false
  }

  // Sabotage
  private setSabotage() {
    audioService.playClickButton()
    this.enabled111 = false
    this.enabled11R = false
    this.enabledAbsolutePro = false
    this.enabledAllOrNothing = true
    this.enabledBBE = true
    this.enabledBull = false
    this.enabledBullForLand = false
    this.enabledConquerAll = false
    this.enabledDestroy = true
    this.enabledESPPlus = false
    this.enabledFriendlyFire = false
    this.enabledTopToBottom = false
  }

  // Thermit
  private setThermit() {
    audioService.playClickButton()
    this.enabled111 = true
    this.enabled11R = false
    this.enabledAbsolutePro = false
    this.enabledAllOrNothing = false
    this.enabledBBE = true
    this.enabledBull = false
    this.enabledBullForLand = false
    this.enabledConquerAll = true
    this.enabledDestroy = true
    this.enabledESPPlus = false
    this.enabledFriendlyFire = false
    this.enabledTopToBottom = false
  }

  // Wicked
  private setWicked() {
    audioService.playClickButton()
    this.enabled111 = true
    this.enabled11R = true
    this.enabledAbsolutePro = false
    this.enabledAllOrNothing = true
    this.enabledBBE = true
    this.enabledBull = false
    this.enabledBullForLand = false
    this.enabledConquerAll = false
    this.enabledDestroy = false
    this.enabledESPPlus = false
    this.enabledFriendlyFire = false
    this.enabledTopToBottom = false
  }

  // Outland
  private setOutland() {
    audioService.playClickButton()
    this.enabled111 = true
    this.enabled11R = true
    this.enabledAbsolutePro = false
    this.enabledAllOrNothing = true
    this.enabledBBE = true
    this.enabledBull = false
    this.enabledBullForLand = true
    this.enabledConquerAll = false
    this.enabledDestroy = true
    this.enabledESPPlus = false
    this.enabledFriendlyFire = false
    this.enabledTopToBottom = true
  }

  // Arrival
  private setArrival() {
    audioService.playClickButton()
    this.enabled111 = true
    this.enabled11R = true
    this.enabledAbsolutePro = false
    this.enabledAllOrNothing = false
    this.enabledBBE = true
    this.enabledBull = true
    this.enabledBullForLand = false
    this.enabledConquerAll = true
    this.enabledDestroy = false
    this.enabledESPPlus = false
    this.enabledFriendlyFire = true
    this.enabledTopToBottom = false
  }

  // Lockout
  private setLockout() {
    audioService.playClickButton()
    this.enabled111 = false
    this.enabled11R = true
    this.enabledAbsolutePro = false
    this.enabledAllOrNothing = false
    this.enabledBBE = true
    this.enabledBull = false
    this.enabledBullForLand = true
    this.enabledConquerAll = false
    this.enabledDestroy = true
    this.enabledESPPlus = false
    this.enabledFriendlyFire = false
    this.enabledTopToBottom = false
  }

  private unsetVariant() {
    audioService.playClickButton()
    this.enabled111 = false
    this.enabled11R = false
    this.enabledAbsolutePro = false
    this.enabledAllOrNothing = false
    this.enabledBBE = false
    this.enabledBull = false
    this.enabledBullForLand = false
    this.enabledConquerAll = false
    this.enabledDestroy = false
    this.enabledESPPlus = false
    this.enabledFriendlyFire = false
    this.enabledTopToBottom = false
  }

  private start() {
    window.gameplay.numTeams = this.numTeams
    window.gameplay.numPlayers = this.numPlayers
    window.gameplay.players = this.allPlayers

    window.gameplay.initialScore = this.initialScore

    window.gameplay.enabledBull = this.enabledBull
    window.gameplay.enabledBBE = this.enabledBBE
    window.gameplay.enabledBullForLand = this.enabledBullForLand
    window.gameplay.enabledTopToBottom = this.enabledTopToBottom
    window.gameplay.enabledFriendlyFire = this.enabledFriendlyFire
    window.gameplay.enabledConquerAll = this.enabledConquerAll
    window.gameplay.enabled11R = this.enabled11R
    window.gameplay.enabled111 = this.enabled111
    window.gameplay.enabledDestroy = this.enabledDestroy
    window.gameplay.enabledAllOrNothing = this.enabledAllOrNothing
    window.gameplay.enabledAbsolutePro = this.enabledAbsolutePro
    window.gameplay.enabledESPPlus = this.enabledESPPlus

    window.gameplay.numDarts = this.numDarts

    audioService.playGameOn()

    this.$router.push('/game')
  }

  private roll() {
    this.enabledBull = this.randomBool()
    this.enabledBBE = this.randomBool()
    this.enabledBullForLand = this.randomBool()
    this.enabledTopToBottom = this.randomBool()
    this.enabledFriendlyFire = this.randomBool()
    this.enabledConquerAll = this.randomBool()
    this.enabled11R = this.randomBool()
    this.enabled111 = this.randomBool()
    this.enabledDestroy = this.randomBool()
    this.enabledAllOrNothing = this.randomBool()
    this.enabledAbsolutePro = this.randomBool()
    this.enabledESPPlus = this.enabledBull && this.randomBool()

    if (this.enabledBBE) {
      this.enabledAbsolutePro = false
    }
    if (this.enabledAllOrNothing) {
      this.enabledConquerAll = false
      this.enabledBull = false
    }
    if (this.enabledAbsolutePro) {
      this.enabledBBE = false
      this.enabledConquerAll = false
    }
    if (this.enabledBull) {
      this.enabledBullForLand = false
      this.enabledDestroy = false
      this.enabledAllOrNothing = false
    }
    if (this.enabledDestroy) {
      this.enabledBull = false
    }
    if (this.enabledBullForLand) {
      this.enabledBull = false
      this.enabledConquerAll = false
    }
    if (this.enabledConquerAll) {
      this.enabledBullForLand = false
      this.enabledAllOrNothing = false
      this.enabledAbsolutePro = false
    }
  }

  private randomBool(): boolean {
    return Math.random() >= 0.5
  }

  private created() {
    window.gameplay = this.gameplay
  }
}
